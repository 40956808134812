/****************************************
section
****************************************/
.app-feature {

    &-1 {
        .app-feature-wrapper {
            padding: 9rem 0 5rem 0;

            @media (max-width: 991px) {
                padding: 0;
            }
        }
    }

    &-2 {
        .app-feature-wrapper {
            padding: 0 0 5rem 0;

            @media (max-width: 991px) {
                padding: 0;
            }
        }
    }

    .section-heading {
        margin-bottom: 9.5rem;
        @media (max-width: 991px) {
            margin-bottom: 3rem;
        }
    }

    &-row {
        margin-top: 9rem;
        @media (max-width: 991px) {
            margin-top: 0;
        }
    }
}

/****************************************
single
****************************************/
.app-feature-single {
    text-align: center;  
    
    position: relative;
    overflow: hidden;
    padding: .25rem;

    &::before {
        content: '';
        display: block;
        position: absolute;
        
        z-index: 1;
        bottom: 100%;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--gradient-purple-rgb);
        background: linear-gradient(0deg, var(--gradient-purple-rgba) 0%, var(--gradient-blue-rgba) 50%);
        @include animateIt(.4s);
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
       
        z-index: 1;
        top: 100%;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--gradient-pink-rgba);
        background: linear-gradient(0deg, var(--gradient-pink-rgba) 50%, var(--gradient-purple-rgba) 100%);
        @include animateIt(.4s);
    }

    &-wrapper {
        padding: 3.5rem 3rem 5.7rem 3rem;
        background-color: #fff;
        @include border-radius(1.7rem);
        position: relative;
        z-index: 2;

        @media (max-width: 991px) {
            padding: 1.5rem 3rem 3rem 3rem;
        }
    }

    @include border-radius(2rem);

    .icon {
        font-size: 8rem;
        
        i {
            font-size: 10rem;
            @include icon-gradient;
        }
    }

    h3 {
        font-size: 2rem;
        font-weight: 600;
        margin: .9rem 0 2.3rem 0;
    }


    .circle {
        position: relative;
        overflow: hidden;

        width: 3.7rem;
        height: 3.7rem;
        border-radius: 50%;
        background-color: var(--gradient-pink);

        font-size: 2.4rem;
        color: #fff;

        position: absolute;
        bottom: -20%;
        right: -20%;
        z-index: 2;

        i {
            font-size: 2.4rem;
            color: #fff;

            @include absoluteCenter;
            @include animateIt(.3s);
        }

        .hover {
            top: 125%;
        }

        &:hover, &.hover {
            .hover {
                top: 50%;
            }

            i:not(.hover) {
                top: -125%;
            }
        }

        @include animateIt(.25s);
    }

    @include drop-shadow($color: transparent);
    @include animateIt;

    &:hover {
        @include drop-shadow($color: var(--pink-rgba-1));
        .circle {
            bottom: 1rem;
            right: 1rem;
        }
        &::before {
            bottom: 50%;
            left: 0;
        }
        &::after {
            top: 50%;
            left: 0%;
        }
    }

    &-1 {
        @include drop-shadow($color: var(--pink-rgba-1));
        .app-feature-single-wrapper {
            padding: 2.4rem 4rem 4.6rem 4rem;
        }

        &::before {
            bottom: 50%;
            left: 0;
        }

        &::after {
            top: 50%;
            left: 0%;
        }
    }

    &-2 {
        @include drop-shadow($color: var(--pink-rgba-1));
        .icon i::before {
            background: var(--gradient-pink);
            background: -webkit-linear-gradient(45deg, var(--gradient-pink) 10%, var(--gradient-purple) 25%, var(--gradient-blue) 40%, #fff 55%, #fff 100%);
            background: -moz-linear-gradient(45deg, var(--gradient-pink) 10%, var(--gradient-purple) 25%, var(--gradient-blue) 40%, #fff 55%, #fff 100%);
            background: linear-gradient(45deg, var(--gradient-pink) 10%, var(--gradient-purple) 25%, var(--gradient-blue) 40%, #fff 55%, #fff 100%);
            background-size: 300%;
            background-position: bottom left;
            background-repeat: no-repeat;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .icon i::before,
        h3,
        p {
            @include animateIt;
        }

        .circle {
            background-color: #fff;
            i {
                color: var(--gradient-pink);
            }
        }
        
        .app-feature-single-wrapper::after {
            content: '';

            position: absolute;
            top: 100%;
            left: 0;

            opacity: 0;
            visibility: hidden;

            z-index: -1;

            width: 100%;
            height: 100%;

            @include border-gradient-triple(-180deg, $first-pos: 0%, $second-pos: 50%, $third-pos: 100%);
            @include border-radius(1.5rem);
            @include animateIt;
        }

        &:hover {
            .icon i::before {
                // background: #fff;
                // -webkit-background-clip: text;
                // -webkit-text-fill-color: transparent;
                background-position: top right;
            }
            h3, p {
                color: #fff;
            }
            .app-feature-single-wrapper::after {
                top: 0;

                opacity: 1;
                visibility: visible;
            }
        }

        &::before {
            bottom: 50%;
            left: 0;
        }

        &::after {
            top: 50%;
            left: 0%;
        }

    }

}

/****************************************
feature section
****************************************/
.feature-section {
    

    &-content {
        > p {
            padding-right: 12rem;
            margin-top: 3rem;
        }
        .icon-text-group {
            margin-top: 3.5rem;
        }
        .button {
            margin-top: 4rem;
        }

        @media (max-width: 991px) {
            > p {
                padding-right: 0;
            }
        }
    }

    &-image {
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .phone {
            position: relative;
            z-index: 3;
            @include drop-shadow;
            max-width: 100%;
        }
        .image {
            position: absolute;
            top: 50%;
            left: -10%;
            transform: translateY(-50%);
            z-index: 2;
            @include drop-shadow;
            max-width: 100%;
        }
        .background-pattern {
            width: 100%;
            height: 90%;
            left: initial;

            top: 50%;
            transform: translateY(-50%);

            right: -7rem;
        }
    }

    &-spacing-1 {
        .feature-section-wrapper {
            padding: 15rem 0 10rem 0;
            @media (max-width: 991px) {
                padding: 5rem 0;
            }
        }
    }

    &-spacing-2 {
        .feature-section-wrapper {
            padding: 15rem 0 20rem 0;
            @media (max-width: 991px) {
                padding: 5rem 0;
            }
        }
    }

    &-spacing-3 {
        .feature-section-wrapper {
            padding: 5rem 0 15rem 0;
            @media (max-width: 991px) {
                padding: 5rem 0;
            }
        }
    }

    @media (max-width: 992px) {
        & &-image {
            justify-content: center;
            margin-bottom: 5rem;
    
            .background-pattern {
                left: 50%;
                right: initial;
                transform: translate(-50%, -50%);
            }
        }
    }

    &-0 {
        .feature-section-image {
            .image {
                @include border-radius(2rem);
                @media (max-width: 992px) { 
                    left: -15%;
                } 
            }
        }
        
        @media (max-width: 575px) {
            .feature-section-image {
                justify-content: flex-end;

                .phone {
                    max-width: 60%;
                }

                .image {
                    left: -5%;
                    max-width: 50%;
                }
            }
        }
    }
    
    &-1 {
        .feature-section-content {
            > p {
                padding-right: 0;
            }
            .icon-text-1-group {
                margin-top: -.8rem;
            }
        }

        .feature-section-image {
            justify-content: flex-start;
            .image {
                left: initial;
                right: -10%;
                @include border-radius-reverse(2rem);
                @media (max-width: 992px) { 
                    right: -10%;
                } 
            }
            .background-pattern {
                right: initial;
                left: -7rem;
            }
        }

        @media (max-width: 991px) {
            .feature-section-image {
                justify-content: center;
                .background-pattern {
                    left: 50%;
                    right: initial;
                    transform: translate(-50%, -50%);
                }
            }
        }

        @media (max-width: 575px) {
            .feature-section-image {
                justify-content: flex-start;

                .phone {
                    max-width: 60%;
                }

                .image {
                    right: -5%;
                    max-width: 50%;
                }
            }
        }
    }
    
}

/****************************************
features
****************************************/
.features {

    .section-heading {
        margin-bottom: 9rem;

        @media (max-width: 991px) {
            margin-bottom: 0;
        }
    }

    &-row {
        padding-bottom: 4.9rem;

        @media (max-width: 991px) {
            padding-bottom: 0;
        }

        div[class*="col"] {
            &:nth-child(odd) {
                border-right: .1rem solid $color-grey-3;
            }

            &:nth-child(-n+2) {
                .features-single {
                    padding: 3rem 7rem 9.2rem 7rem;
                }
            }

            &:nth-last-child(-n+2) {
                .features-single {
                    padding: 9.2rem 7rem 3rem 7rem;
                }
            }

            &:nth-child(n+3) {
                border-top: .1rem solid $color-grey-3;
            }

            @media (max-width: 991px) {
                &:nth-child(-n+2), &:nth-last-child(-n+2) {
                    .features-single {
                        padding: 5rem 3rem 5rem 3rem;
                    }
                }
            }

            @media (max-width: 767px) {
                &:nth-child(n+2) {
                    border-top: .1rem solid $color-grey-3;
                }

                &:nth-child(odd) {
                    border-right: none;
                }

                &:nth-child(-n+2), &:nth-last-child(-n+2) {
                    .features-single {
                        padding: 5rem 0 5rem 0;
                    }
                }
                
                .features-single {
                    padding: 5rem 0 5rem 0;
                }
            }
        }
    }

    &-single {
        text-align: center;
        padding: 9.2rem 7rem 9.2rem 7rem;

        .icon {
            font-size: 8rem;
            
            i {
                @include icon-gradient;
            }
        }

        h3 {
            font-size: 2rem;
            margin: 1rem 0 2.3rem 0;
        }

        @media (max-width: 991px) {
            padding: 5rem 3rem 5rem 3rem;
        }

        @media (max-width: 767px) {
            padding: 5rem 7rem 5rem 7rem;
        }

    }
}